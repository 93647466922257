import { getAction, getByIdAction, postAction, putAction, deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunLifeHous'


const listDictApi = (params) => getAction(baseURL + "/list", params);

const addHourtApi = (params) => postAction(baseURL + "/add", params);

const editHourtApi = (params) => putAction(baseURL + "/edit", params);

const deleteById = (params) => deleteByIdAction(baseURL + "/deleteById", params);

const selectById = (params) => getByIdAction(baseURL + "/selectById", params);

const selectByIdStatus = (params) => getAction(baseURL + "/selectByIdStatus", params);

const editStatus = (params) => getByIdAction(baseURL + "/editStatus", params);



export {
    listDictApi,
    editHourtApi,
    deleteById,
    selectById,
    selectByIdStatus,
    editStatus,
    addHourtApi
}
