<template>
  <el-drawer
    :title="title"
    :before-close="handleClose"
    :visible.sync="dialog"
    direction="rtl"
    custom-class="demo-drawer"
    ref="drawer"
    size="50%"
  >
    <!-- 配置区域 -->
    <div>
      <el-form :model="addFrom" layout="inline" :inline="true">
        <el-row :gutter="24">
          <el-form-item label="地区" prop="areaCode">
            <el-cascader
              v-model="areaValue"
              :options="areaList"
              :props="{ value: 'areaCode', label: 'areaName'}"
              @change="handleChange"
              filterable
              ref="cascader"
            ></el-cascader>
          </el-form-item>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="详细地址" prop="detailedAddress">
              <el-input style="width:300px" v-model="addFrom.detailedAddress" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="淘宝地址" prop="taobaoAddress">
              <el-input v-model="addFrom.taobaoAddress" style="width:300px" class="inputTao" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付费多少" width="120px" prop="pay">
              <el-input-number width="20px" v-model="addFrom.pay" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="房租缴纳日期" width="120px" prop="rentDate">
              <el-input width="20px" v-model="addFrom.rentDate" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始时间" width="120px" prop="startTime">
              <el-date-picker
                v-model="addFrom.startTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束时间" width="120px" prop="endTime">
              <el-date-picker
                v-model="addFrom.endTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="结构" width="120px" prop="structure">
              <el-input width="20px" v-model="addFrom.structure" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="押金" width="120px" prop="deposit">
              <el-input-number width="20px" v-model="addFrom.deposit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="房租" width="120px" prop="rent">
              <el-input-number width="20px" v-model="addFrom.rent" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="电费" width="120px" prop="power">
              <el-input-number width="20px" v-model="addFrom.power" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="天然气" width="120px" prop="gasFee">
              <el-input-number width="20px" v-model="addFrom.gasFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="水费" width="120px" prop="water">
              <el-input-number width="20px" v-model="addFrom.water" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="垃圾费" width="120px" prop="rubbishFee">
              <el-input-number width="20px" v-model="addFrom.rubbishFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物业费" width="120px" prop="propertyFee">
              <el-input-number width="20px" v-model="addFrom.propertyFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="网费" width="120px" prop="networkMoney">
              <el-input-number width="20px" v-model="addFrom.networkMoney" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="居住人" width="120px" prop="resident">
              <el-input width="20px" v-model="addFrom.resident" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="绑定人" width="120px" prop="userId">
              <el-input width="20px" v-model="addFrom.userId" />
            </el-form-item>
          </el-col>-->
          <el-col :span="8">
            <el-form-item label="房东" width="120px" prop="landlord">
              <el-input width="20px" v-model="addFrom.landlord" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注" width="120px" prop="remark">
              <el-input style="width:500px" v-model="addFrom.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="addHoursButton">确 定</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import {
  selectByIdStatus,
  listDictApi,
  editHourtApi,
  addHourtApi,
  selectById
} from "@/api/my/myconfig/xunLife.js";
export default {
  data() {
    return {
      dialog: false,
      editShow: false,
      title: "",
      // 表单参数
      addFrom: {},
      // 表单校验
      id: null,
      type: 1,
      areaList: [],
      areaValue: []
    };
  },
  methods: {
    show(id, type) {
      this.areaList = this.$parent.areaList;
      this.dialog = true;
      if (type == 2) {
        this.selectByIdButton(id);
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
      this.type = type;
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then(_ => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch(_ => {});
    },
    cancelForm() {
      this.dialog = false;
      this.editShow = false;
      this.addFrom = {};
      this.$parent.selectByIdStatusButton();
      this.$parent.listDictApiButton();
    },
    addHoursButton() {
      this.addFrom.provinceCode = this.areaValue[0];
      this.addFrom.cityCode = this.areaValue[1];
      this.addFrom.areaCode = this.areaValue[2];
         this.addFrom.pay=this.addFrom.pay/100;
      if (this.type == 1) {
        this.addFrom.status = 1;
        addHourtApi(this.addFrom).then(res => {
          this.cancelForm();
        });
      }
      if (this.type == 2) {
        editHourtApi(this.addFrom).then(res => {
          this.cancelForm();
        });
      }
    },
    selectByIdButton(id) {
      selectById(id).then(res => {
        this.addFrom = res.result;
         this.addFrom.pay=this.addFrom.pay*100;
      });
    },
    handleChange() {
      console.log(this.areaValue);
    }
  }
};
</script>
<style scoped>
</style>