<template>
  <div class="app-container">
    <!-- 配置区域 -->
    <div>
      <el-card>
        <div style="float: right">
          <el-button
            type="primary"
            style="margin: -20px 20px 10px 10px"
            @click="editHourtButton"
            >保存</el-button
          >
        </div>
      </el-card>

      <el-form :model="addFrom" layout="inline" :inline="true">
        <el-form-item label="地区" prop="areaCode">
          <el-cascader
            v-model="areaValue"
            :options="areaList"
            :props="{ value: 'areaCode', label: 'areaName' }"
            @change="handleChange"
            filterable
            ref="cascader"
          ></el-cascader>
        </el-form-item>
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="详细地址" prop="detailedAddress">
              <el-input
                style="width: 300px"
                v-model="addFrom.detailedAddress"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="淘宝地址" prop="taobaoAddress">
              <el-input
                v-model="addFrom.taobaoAddress"
                style="width: 300px"
                class="inputTao"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="付费百分之几" width="120px" prop="pay">
              <el-input-number width="20px" v-model="addFrom.pay" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="房租缴纳日期" width="120px" prop="rentDate">
              <el-input width="20px" v-model="addFrom.rentDate" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始时间" width="120px" prop="startTime">
              <el-date-picker
                v-model="addFrom.startTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束时间" width="120px" prop="endTime">
              <el-date-picker
                v-model="addFrom.endTime"
                value-format="yyyy-MM-dd"
                type="date"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="结构" width="120px" prop="structure">
              <el-input width="20px" v-model="addFrom.structure" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="押金" width="120px" prop="deposit">
              <el-input-number width="20px" v-model="addFrom.deposit" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="房租" width="120px" prop="rent">
              <el-input-number width="20px" v-model="addFrom.rent" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="电费" width="120px" prop="power">
              <el-input-number width="20px" v-model="addFrom.power" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="天然气" width="120px" prop="gasFee">
              <el-input-number width="20px" v-model="addFrom.gasFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="水费" width="120px" prop="water">
              <el-input-number width="20px" v-model="addFrom.water" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="垃圾费" width="120px" prop="rubbishFee">
              <el-input-number width="20px" v-model="addFrom.rubbishFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物业费" width="120px" prop="propertyFee">
              <el-input-number width="20px" v-model="addFrom.propertyFee" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="网费" width="120px" prop="networkMoney">
              <el-input-number width="20px" v-model="addFrom.networkMoney" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="居住人" width="120px" prop="resident">
              <el-input width="20px" v-model="addFrom.resident" />
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="绑定人" width="120px" prop="userId">
              <el-input width="20px" v-model="addFrom.userId" />
            </el-form-item>
          </el-col>-->
          <el-col :span="8">
            <el-form-item label="房东" width="120px" prop="landlord">
              <el-input width="20px" v-model="addFrom.landlord" />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="24">
            <el-form-item label="备注" width="120px" prop="remark">
              <el-input style="width: 500px" v-model="addFrom.remark" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 配置区域 -->
    <div>
      <el-form :model="queryParams" layout="inline" :inline="true">
        <el-row>
          <el-col :md="6" :sm="24" style="width: 70%">
            <el-button type="primary" @click="addHoursButton()"
              >新增历史</el-button
            >
            <el-button type="primary" @click="listDictApiButton()"
              >搜索</el-button
            >
            <el-button type="primary" @click="primaryRest()">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!--     列表区域   -->
    <el-table :data="dataList" row-key="id" border>
      <el-table-column label="序号" align="center" width="65">
        <template slot-scope="scope">
          <el-radio
            :label="scope.$index"
            v-model="radio"
            @change.native="getCurrentRow(scope.row)"
            :disabled="scope.row.yearId == null ? true : false"
          />
        </template>
      </el-table-column>
      <el-table-column label="开始时间" align="center" prop="startTime" />
      <el-table-column label="结束时间" align="center" prop="endTime" />
      <el-table-column label="详细地址" align="center" prop="detailedAddress" />
      <el-table-column label="结构" align="center" prop="structure" />
      <el-table-column label="房租" align="center" prop="rent" />
      <el-table-column
        label="房屋状态"
        align="center"
        prop="status"
        :formatter="hoursStatusFormat"
      />
      <el-table-column
        label="操作"
        align="center"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="scope.row.status == 1 ? false : true"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="editButton(scope.row)"
            >编辑</el-button
          >
          <el-button
            :disabled="scope.row.status == 1 ? false : true"
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="editStatusButton(scope.row)"
            >设置使用</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      :total="queryParams.total"
      :page-size.sync="queryParams.pageSize"
      :current-page.sync="queryParams.currentPage"
    />
    <addHours ref="addHoursFrom" />
  </div>
</template>


<script>
import {
  selectByIdStatus,
  listDictApi,
  editHourtApi,
  addHourtApi,
  editStatus,
} from "@/api/my/myconfig/xunLife.js";
import addHours from "./modules/addHours";
import { getAreaDataApi } from "@/api/system/mune/menuCoupon.js";
export default {
  components: { addHours },
  data() {
    return {
      // 选中数组
      radio: null,
      ids: null,
      areaValue: [],
      // 查询参数
      queryParams: {
        current: 1,
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      // 表单参数
      form: {},
      addFrom: {},
      // 表单校验
      rules: {},
      optFrom: {},
      dataList: [],
      year: null,
      id: null,
      areaList: [],
    };
  },
  watch: {
    "queryParams.currentPage"(val) {
      this.listDictApiButton();
    },
    "queryParams.pageSize"(val) {
      this.listDictApiButton();
    },
  },
  created() {
    this.listDictApiButton();
    this.areaDataList();
    this.selectByIdStatusButton();
  },
  methods: {
    selectByIdStatusButton() {
      selectByIdStatus().then((res) => {
        if (res.result != null) {
          this.addFrom = res.result;
          this.id = res.result.id;
          this.areaValue = res.result.areaList;
          this.addFrom.pay = this.addFrom.pay * 100;
        }
      });
    },
    listDictApiButton() {
      listDictApi(this.queryParams).then((res) => {
        this.dataList = res.result.records;
        this.queryParams.total = res.result.total;
      });
    },
    editHourtButton() {
      //特殊处理
      this.addFrom.provinceCode = this.areaValue[0];
      this.addFrom.cityCode = this.areaValue[1];
      this.addFrom.areaCode = this.areaValue[2];
      this.addFrom.pay = this.addFrom.pay / 100;
      if (this.id == null) {
        this.addFrom.status = 2;
        addHourtApi(this.addFrom).then((res) => {
          this.selectByIdStatusButton();
          this.listDictApiButton();
        });
      } else {
        editHourtApi(this.addFrom).then((res) => {
          this.selectByIdStatusButton();
          this.listDictApiButton();
        });
      }
    },
    addHoursButton() {
      this.$refs.addHoursFrom.show(null, 1);
    },
    editButton(row) {
      this.$refs.addHoursFrom.show(row.id, 2);
    },
    editStatusButton(row) {
      editStatus(row.id).then((res) => {
        this.selectByIdStatusButton();
        this.listDictApiButton();
      });
    },
    hoursStatusFormat(row, column) {
      if (row.status == 1) {
        return "草稿";
      }
      if (row.status == 2) {
        return "使用中";
      }
    },
    areaDataList() {
      getAreaDataApi({}).then((res) => {
        this.areaList = res.result;
      });
    },
    handleChange() {
      console.log(this.areaValue);
    },
  },
};
</script>
<style lang="less" scoped>
.inputTao {
  width: 130px;
}
.el-cascader-panel {
  height: 40px;
}
</style>



