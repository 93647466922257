import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/common/noAuthority'


const getCouponListApi =(params)=>postAction(baseURL+"/getCouponList",params);

const getAreaDataApi =(params)=>postAction(baseURL+"/getAreaData",params);
export{
    getCouponListApi,
    getAreaDataApi

}
